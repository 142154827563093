/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}


// 公用函数


// 去两端空格
export const trimStr = (s) => {
  return s.replace(/(^\s*)/, "").replace(/(\s*$)/, "");
}

// 获取当前日期并转化格式
export const getCurrentTime = () => {
  var date = new Date();//当前时间
  var month = zeroFill(date.getMonth() + 1);//月
  var day = zeroFill(date.getDate());//日
  var hour = zeroFill(date.getHours());//时
  var minute = zeroFill(date.getMinutes());//分
  var second = zeroFill(date.getSeconds());//秒
  //当前时间
  var curTime = date.getFullYear() + "-" + month + "-" + day
          + " " + hour + ":" + minute + ":" + second;
  return curTime;
}

function zeroFill(i){
  if (i >= 0 && i <= 9) {
      return "0" + i;
  } else {
      return i;
  }
}

//设置无限cookies 时间
export const setCookie=(key,value)=>{
  var date = new Date();
  date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
  document.cookie = key + "=" + value + "; expires=" + date.toGMTString();
}

//获取cookies某个键的值
export const getCookie=(key)=>{
  var arr,reg=new RegExp("(^| )"+key+"=([^;]*)(;|$)");
  if(arr=document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}

/**
 * 一串数字转金额，每隔三位加逗号
 */
export const numFun = (obj) => {
  obj = obj+''
  var newStr = "";
  var count = 0;
  if(obj.indexOf(".")==-1){
     if(obj.charAt(0) == '0'){ //不存在小数点时，判断第一位数字是否为0
        obj = obj.substring(1);  
    }
    for(var i=obj.length-1;i>=0;i--){
      if(count % 3 == 0 && count != 0){
        newStr = obj.charAt(i) + "," + newStr;
      }
      else{
        newStr = obj.charAt(i) + newStr;
      }
      count++;
    }
    obj = newStr + ".00";
  }else{
    for(var i=obj.indexOf(".")-1;i>=0;i--){
      if(count % 3 == 0 && count != 0){
          newStr = obj.charAt(i) + "," + newStr;
      }
      else{
          newStr = obj.charAt(i) + newStr;
      }
      count++;
    }
    obj = newStr + (obj + "00").substr((obj + "00").indexOf("."),3);
  }
  return obj

}

