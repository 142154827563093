<template>
  <div class="KeyBoard">
    <!-- {{this.keyValue}} -->
    <div class="KeyBoard_box">
      
      <div class="key_item" v-for="(item,index) in keyList" :key="index" @touchstart.stop.prevent="handleKeyNum(item.val)" @click.stop.prevent="handleKeyNum(item.val)">
        <button>{{item.key}}</button>
      </div>
      <div class="key_item key_item_0" @touchstart.stop.prevent="handleKeyNum(0)" @click.stop.prevent="handleKeyNum(0)">
        <button>0</button>
      </div>
      <div class="key_tool">
        <!--  <div class="key_item key_delete ac_color" @touchstart.stop="handleDelete()" @click.stop.prevent="handleDelete()">-->
        <div class="key_item key_delete ac_color" @click.stop.prevent="handleDelete()">
          <button>
            <!-- <i class="iconfont icon-icon_jianpan_jianpanshanchu2x"></i> -->
            <svg viewBox="0 0 32 22" xmlns="http://www.w3.org/2000/svg" class="van-key__delete-icon"><path d="M28.016 0A3.991 3.991 0 0132 3.987v14.026c0 2.2-1.787 3.987-3.98 3.987H10.382c-.509 0-.996-.206-1.374-.585L.89 13.09C.33 12.62 0 11.84 0 11.006c0-.86.325-1.62.887-2.08L9.01.585A1.936 1.936 0 0110.383 0zm0 1.947H10.368L2.24 10.28c-.224.226-.312.432-.312.73 0 .287.094.51.312.729l8.128 8.333h17.648a2.041 2.041 0 002.037-2.04V3.987c0-1.127-.915-2.04-2.037-2.04zM23.028 6a.96.96 0 01.678.292.95.95 0 01-.003 1.377l-3.342 3.348 3.326 3.333c.189.188.292.43.292.679 0 .248-.103.49-.292.679a.96.96 0 01-.678.292.959.959 0 01-.677-.292L18.99 12.36l-3.343 3.345a.96.96 0 01-.677.292.96.96 0 01-.678-.292.962.962 0 01-.292-.68c0-.248.104-.49.292-.679l3.342-3.348-3.342-3.348A.963.963 0 0114 6.971c0-.248.104-.49.292-.679A.96.96 0 0114.97 6a.96.96 0 01.677.292l3.358 3.348 3.345-3.348A.96.96 0 0123.028 6z" fill="currentColor"></path></svg>
          </button>
        </div>
        <slot name="diy_btn"></slot>
        <div class="key_item key_finish" :disabled="finish_disabled" @click.stop="finish_disabled?'':handleFinish()">
          <button>
            确定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 参数:
 *  1. v-model: 绑定值
 *  2. k_finish_disabled: 完成按钮是否可用
 *  2. k_finish_text: 完成按钮文字
 *  3. k_decimal: 保留小数位数,Number型   默认: 2
 *  6. @finish(value): 输入框失焦事件, 回调参数 为绑定值
 *  6. @input(value): 输入框输入事件, 回调参数 为绑定值
 */
export default {
  name: "KeyBoardInput",
  model: {
    prop: "k_value",
    event: "setValue"
  },
  props: {
    k_value: "",
    k_decimal: {
      type: [String, Number],
      default: 2
    },
    k_finish_text: {
      type: [String, Number],
      default: "完成"
    },
    k_finish_disabled: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    k_value: {
      handler(newValue, oldValue){ 
        this.keyValue = newValue+'';
      },
      deep:true
    },
    k_decimal: {
      handler(newValue, oldValue){ 
        this.decimal = newValue
      },
      deep:true
    },
    k_finish_text: {
      handler(newValue, oldValue){
        this.finish_text = newValue
      },
      deep:true
    },
    k_finish_disabled: {
      handler(newValue, oldValue){
        this.finish_disabled = newValue
      },
      deep:true
    },
  },
  data() {
    return {
      keyList:[{key:1,val:1},{key:4,val:4},{key:7,val:7},{key:"00",val:'00'},{key:2,val:2},{key:5,val:5},{key:8,val:8},{key:0,val:0},{key:3,val:3},{key:6,val:6},{key:9,val:9},{key:"·",val:"."}],
      // prop 里的值
      value: this.k_value + "", // 用来存 输入后 的老值
      decimal: this.k_decimal,
      finish_text:this.k_finish_text,  // 完成按钮文字
      finish_disabled:this.k_finish_disabled,  // 完成按钮是否禁用

      // 页面的变量
      keyValue: "",
      // 非法值
      aIllegal: ["00","01","02","03","04","05","06","07","08","09","0..","."],

      keyValue:'',


    };
  },
  created() {
    
  },
  mounted() {},
  methods: {
    // 清空
    clear(){
      this.keyValue = ''
    },
    // 按下了按键 数字
    handleKeyNum(add_val){
      var audio_val = add_val 
      var old_val = this.keyValue
      if(String(old_val).length>8){
        return false
      }
      // 如果输入了AC 把旧的值 变为 0
      if (add_val == "-1") {
        old_val = "";
        add_val = "0";
      }
      // 如果只有一个 0 的 话 且输入不是. 的话吧old_val置空
      if (old_val === "0" && add_val !== ".") {
        old_val = "";
      }

      var new_val = old_val + "" + add_val
      
      /*检验新值, 如果没有通过检测, 恢复值*/
      if (!this.passCheck(new_val)) {
        this.keyValue = old_val;
        return;
      }else{
        this.keyValue = new_val
      }
      var audio = ''
      if(audio_val == "-1"){
        audio = "归零"
      }else if(audio_val == "."){
        audio = "点"
      }else{
        audio = audio_val
      }
      this.$emit("input", this.keyValue); // 将当前对象 evt 传递到父组件
    },
    // 按下了完成按钮
    handleFinish(){
      this.keyValue = Number(this.keyValue) + ""
      this.$emit("finish", this.keyValue);
    },
    // 通知父级
    // notify() {
    //   this.keyValue = this.keyValue;
    //   this.$emit("setValue", this.real_val);
    //   this.$emit("input", this.real_val); // 将当前对象 evt 传递到父组件
    // },
    // 删除按钮
    handleDelete() {
        // console.log( this.keyValue)
       //删除的时候触发的条件
      /*删除值并不会触发值的校验, 所以需要手动再触发一次*/
      this.keyValue = this.keyValue.slice(0, -1);    //自己



      // let keyValue=this.keyValue.slice(0, -1);
      // if(this.keyValue === ""){
      //   this.keyValue = '0'
      // }
      this.$emit("input", this.keyValue); // 将当前对象 evt 传递到父组件
    },
    passCheck(val) {
      /*验证规则*/
      let aRules = [this.illegalInput, this.illegalValue, this.accuracy];
      return aRules.every(item => {
        return item(val);
      });
    },
    /*非法输入*/
    illegalInput(val) {
      if (this.aIllegal.indexOf(val) > -1) {
        return false;
      }
      return true;
    },
    /*非法值*/
    illegalValue(val) {
      if (parseFloat(val) != val) {
        return false;
      }
      return true;
    },
    /*验证精度*/
    accuracy(val) {
      let v = val.split(".");
      if (v[0].length > this.inter) {
        return false;
      }
      if (v[1] && v[1].length > this.decimal) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="less" scoped>
.KeyBoard {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f2f3f5;
  user-select: none;
  overflow: hidden;
  .KeyBoard_box{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 3.34rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.12rem 0 0 0.12rem;
    box-sizing: border-box;
    .key_item{
      width: calc(25% - 0.12rem);
      height: calc(25% - 0.12rem);
      margin: 0 0.12rem 0.12rem 0;
      &.key_item_0{
        width: calc(50% - 0.18rem);
        position: absolute;
        left: 0.12rem;
        bottom: 0;
      }
      button{
        width: 100%;
        height: 100%;
        display: block;
        background-color: #fff;
        border-radius: 0.1rem;
        font-size: 0.74rem;
        font-weight: 500;
        color: #404040;
        border:none;
        i{
          font-size: 0.74rem;
        }
        &:active{
          background-color: #e1e1e1;
        }
      }

      &.key_delete{
        flex: 1;
        height: calc(25% - 0.12rem);
        background-color: #fff;
      }
      &.key_finish{
        height: calc(50% - 0.12rem);
        button{
          color: #fff;
          background-color:#259C8A;
        }
      }
      
      &.ac_color{
        button{
          // background-color: #48A1AF !important;
          // color: #fff !important;
          &:active{
            // background-color: #68b2bd !important;
            background-color: #e1e1e1;
          }
        }
      }
      &[disabled]{
        button{
          background-color: #eee;
          color: #999;
          &:active{
            background-color: #eee;
          }
        }
      }
    }
    .key_tool{
      width: calc(25%);
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      .key_item{
        width: calc(100% - 0.12rem);
      }
    }
  }
}
</style>